.container {
  width: 77%;
  height: 95vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.pooMoji {
  font-size: 128px;
}

.reactErrorContainer {
  background: #f5f5f5;
  padding: 26px;
  font-size: 8px;
  border-radius: 12px;
}
