@import '../../../styles/index.scss';
//
// Burger menu custom styles
//
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  top: 18px;
  right: 22px;
}

.bm-burger-bars {
  background: $primary-color;
}

.bm-morph-shape {
  fill: #373a47;
}

.bm-menu {
  background: rgba(24, 24, 25, 0.9);
  color: #b8b7ad;
  z-index: 999;
}

.bm-item-list {
  padding: 0.8em;

  span {
    margin-left: 10px;
    font-weight: 700;
    color: white;
    font-size: 20px;
    line-height: 36px;
    &:hover,
    &:focus {
      color: $primary-color;
    }
  }
}

.bm-item:focus {
  outline: none;
}

.bm-cross {
  background: #bdc3c7;
}

.bm-menu {
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}
