@import '../../../../styles/index.scss';

s.dateDisplay {
  margin-top: 8px;
  font-style: italic;
  font-size: 12px;
  text-align: center;
}

.text {
  padding: 0px 8px 0px 64px;
}

.avatar {
  padding-right: 32px;
}

.title {
  font-size: 18px;
  color: $heading-color;
}

.contentHeader {
  display: flex;
}
