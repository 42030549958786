@import './variables.scss';
@import './mixins.scss';

.cardItem {
  input {
    min-width: 330px !important;
  }
}

#addListuserContainer {
  input {
    min-width: 330px !important;
  }
}
