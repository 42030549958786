.checked svg {
  color: #08cc08;
}

.unchecked svg {
  color: #5d5d5d;
}

.item {
  box-shadow: 0px 1px 4px 0px #d4d4d4;
  margin: 6px 0;
  padding: 8px 8px 0px 8px;
  border-radius: 2px;
}

.hidden {
  visibility: hidden;
}

.actionsContainer {
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
  padding: 4px;
  svg {
    width: 1.5em;
    height: 1.5em;
  }
}
