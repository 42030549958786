@import '../../../styles/index.scss';

.content {
  margin: 24px;
}

@media screen and (max-width: $screen-sm) {
  .content {
    margin: 16px;
  }
}
