@import url(https://fonts.googleapis.com/css2?family=Berkshire+Swash&display=swap);
.cardItem input{min-width:330px !important}#addListuserContainer input{min-width:330px !important}.exception{display:flex;align-items:center;height:80%;min-height:500px}.exception .imgBlock{flex:0 0 62.5%;width:62.5%;padding-right:152px;zoom:1}.exception .imgBlock:before,.exception .imgBlock:after{content:' ';display:table}.exception .imgBlock:after{clear:both;visibility:hidden;font-size:0;height:0}.exception .imgEle{height:360px;width:100%;max-width:430px;float:right;background-repeat:no-repeat;background-position:50% 50%;background-size:contain}.exception .content{flex:auto}.exception .content h1{color:#434e59;font-size:72px;font-weight:600;line-height:72px;margin-bottom:24px}.exception .content .desc{color:orange;font-size:20px;line-height:28px;margin-bottom:16px}.exception .content .actions button:not(:last-child){margin-right:8px}@media screen and (max-width: 1200px){.exception .imgBlock{padding-right:88px}}@media screen and (max-width: 520px){.exception{display:block;text-align:center}.exception .imgBlock{padding-right:0;margin:0 auto 24px}}@media screen and (max-width: 320px){.exception .imgBlock{margin-bottom:-24px;overflow:hidden}}

.OwnerModified_text__3Q4sV{margin-top:8px;font-style:italic;font-size:12px}

.ItemContent_cardItem__T7uwt input{min-width:330px !important}#ItemContent_addListuserContainer__2NDJF input{min-width:330px !important}s.ItemContent_dateDisplay__1JFDb{margin-top:8px;font-style:italic;font-size:12px;text-align:center}.ItemContent_text__1vAga{padding:0px 8px 0px 64px}.ItemContent_avatar__2qbRz{padding-right:32px}.ItemContent_title__E5qnn{font-size:18px;color:#000}.ItemContent_contentHeader__1MBC2{display:flex}

.Item_checked__jTlNE svg{color:#08cc08}.Item_unchecked__S844H svg{color:#5d5d5d}.Item_item__1A3oQ{box-shadow:0px 1px 4px 0px #d4d4d4;margin:6px 0;padding:8px 8px 0px 8px;border-radius:2px}.Item_hidden__1lzfv{visibility:hidden}.Item_actionsContainer__1ohXc{display:flex;justify-content:space-between;margin:0px auto;padding:4px}.Item_actionsContainer__1ohXc svg{width:1.5em;height:1.5em}

.ItemList_itemList__1jK-1{padding:4px 0px}.ItemList_readOnly__uc4Ql{-webkit-user-select:none;user-select:none}

.main{width:100%;height:100%;min-height:100%;transition:0.3s}.main.wide{max-width:1200px;margin:0 auto}

.cardItem input{min-width:330px !important}#addListuserContainer input{min-width:330px !important}.content{margin:24px}@media screen and (max-width: 520px){.content{margin:16px}}

.ReadOnly_emptyListContainer__FdWTP{height:90vh}

.cardItem input{min-width:330px !important}#addListuserContainer input{min-width:330px !important}.bm-burger-button{position:fixed;width:36px;height:30px;top:18px;right:22px}.bm-burger-bars{background:#1890ff}.bm-morph-shape{fill:#373a47}.bm-menu{background:rgba(24,24,25,0.9);color:#b8b7ad;z-index:999}.bm-item-list{padding:0.8em}.bm-item-list span{margin-left:10px;font-weight:700;color:white;font-size:20px;line-height:36px}.bm-item-list span:hover,.bm-item-list span:focus{color:#1890ff}.bm-item:focus{outline:none}.bm-cross{background:#bdc3c7}.bm-menu{padding:2.5em 1.5em 0;font-size:1.15em}

.Header_fixedHeader__2_4Md{position:fixed;top:0;right:0;width:100%;z-index:9;transition:width 0.2s;height:64px;padding:0px}

.ErrorBoundary_container__3qfQd{width:77%;height:95vh;display:flex;flex-direction:column;justify-content:space-between;align-items:center;margin:0 auto}.ErrorBoundary_pooMoji__18jIs{font-size:128px}.ErrorBoundary_reactErrorContainer__3RWf0{background:#f5f5f5;padding:26px;font-size:8px;border-radius:12px}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@viewport {
  orientation: portrait;
}

