$avatar-size-base: 40px;
$avatar-size-sm: 30px;
$avatar-size-lg: 50px;
$body-background: white;
$border-radius-base: 2%;
$border-color-base: blue;
$border-radius-sm: blue;
$border-width-base: null;
$border-style-base: null;
$border-color-split: null;
$btn-height-lg: 40px;
$disabled-color: grey;
$ease-in-out: width;
$component-background: white;
$error-color: red;
$font-size-base: 12px;
$heading-color: blue;
$heading-color: black;
$line-height-base: 22px;
$menu-collapsed-width: 150px;
$primary-color: #1890ff;
$primary-hover: blue;
$primary-1: blue;
$screen-xl: 1200px;
$screen-lg: 968px;
$screen-md: 768px;
$screen-sm: 520px;
$screen-xs: 320px;
$success-color: green;
$text-color-secondary: null;
$text-color: black;
$text-color-secondary: orange;
$warning-color: orange;
